import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header className="p-y-4">
    <Link to="/">
      <svg className="krb" width="42px" height="30px" viewBox="0 0 42 30">
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-188.000000, -133.000000)">
            <path d="M188,133 L230,133 L230,163 L188,163 L188,133 Z M191,136 L191,160 L194,160 L194,149 L196,149 L198,160 L201.5,160 L199,147.5 L201.5,136 L198,136 L196,146 L194,146 L194,136 L191,136 Z M204,136 L204,160 L207,160 L207,149 L209,149 L211,160 L214.5,160 L212,147.5 L214,145 L214,138.5 L212,136 L204,136 Z M207.097412,146.005616 L209.044434,146.005615 L211,143.5 L211,140.5 L210.01416,139.017822 L207.056825,139.017822 L207.097412,146.005616 Z M217,136.057616 L217,160 L225,160 L227,157.507812 L227,150.007812 L225,147.507812 L227,145.007812 L227,138.507812 L225,136.144042 L217,136.057616 Z M220,146 L222,146 L224,143.5 L224,140.5 L223,139 L220,139 L220,146 Z M220,157 L223.044434,157 L224,155.5 L224,151.5 L222,149 L220,149 L220,157 Z"></path>
          </g>
        </g>
      </svg>
    </Link>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
